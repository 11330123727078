import React from 'react';

import MenuItem from './MenuItem';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, faPhone, faFax, faEnvelope, faUser} from '@fortawesome/free-solid-svg-icons'

import Kontaktdaten from './Kontaktdaten';


const Kontakt = (props) => {
    return (
        <div className="contact">
            <Container>                
                <Card
                    style={
                        {
                            backgroundColor: props.bg,
                            border: "none",
                            margin: "10px 0"
                        }
                    }
                    className=""
                    >
                    <Card.Body>
                        <Container>
                        <h3>Muster-Apotheke</h3>
                        <Kontaktdaten />
                            
                            <Row style={{marginTop: "20px"}}>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2014.8941549012607!2d8.776464082209396!3d53.089963677123464!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b1284a8fa807af%3A0x607d00c41276e3d5!2sKonsul-Smidt-Stra%C3%9Fe%2012%2C%2028217%20Bremen!5e0!3m2!1sde!2sde!4v1683735211655!5m2!1sde!2sde" width="100%" height="300" style={{border: 0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </Row>
                        </Container>
                    </Card.Body>
                </Card>
            </Container>
            <Container>
                
            </Container>
        </div>        
    )
}

export default Kontakt;