import logo from './logo.svg';
import './App.css';

import { useState } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

import MainButton from './components/MainButton';
import FooterMenu from './components/FooterMenu';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from './components/Header';
import OpeningHours from './components/OpeningHours';
import Kontakt from './components/Kontakt';
import Empfehlung from './components/Empfehlung';
import Formular from './components/Formular';

function App() {

  const [isActive, setIsActive] = useState(false);

    const handleClick = event => {
    
      setIsActive(current => !current);
    };

    const [QRisActive, setQRisActive] = useState(false);

    const handleClickQR = event => {
    
      setQRisActive(current => !current);
    };
  
  return (
    <div className="App">
      <Container>
        <Row>
          <Header />
        </Row>
        
        <Row>  
          <Col xs="6" className={isActive ?  'active': 'notActive'} onClick={handleClick}>
            <MainButton title="Rezept" bg="#ea464f" dynClass="icon-red" image={3}/>
          </Col> 
          <Col xs="6" className={QRisActive ?  'qr-active': 'qr-notActive'} onClick={handleClickQR}>
            <MainButton title="E-Rezept" bg="#1f8f3a" dynClass="icon" image={0}/>
          </Col>       
        </Row>
        <Formular></Formular>
        <Row>
          <Col xs="6">
            <MainButton title="Termine" bg="#1f8f3a" dynClass="icon" image={6}/>
          </Col>      
          <Col xs="6">
            <MainButton link="https://www.apotheke-neunkirchen.de/" title="Shop" bg="#1f8f3a" dynClass="icon" image={2}/>
          </Col>     
        </Row>
        <Row>
          <Col xs="6">
            <MainButton link="https://www.ratiopharm.de/produkte/rezeptfreie-produkte.html" title="Coupons" bg="#1f8f3a" dynClass="icon" image={4}/>
          </Col>      
          <Col xs="6">
            <MainButton title="Jobs" bg="#1f8f3a" dynClass="icon" image={5}/>
          </Col>     
        </Row>
      </Container>     
      
      <OpeningHours />
      <Kontakt />
      <footer>
        <FooterMenu />
      </footer>
    </div>
  );
}

export default App;
