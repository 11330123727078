import React from 'react';

import MenuItem from './MenuItem';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faHouse } from '@fortawesome/free-solid-svg-icons'


const FooterMenu = (props) => {
    return (
        <Container className="footermenu">
            <Row>
                <Col><MenuItem title="Start" icon={0}/></Col>
                <Col><MenuItem title="Beratung" icon={3}/></Col>
                <Col><MenuItem title="Einnahmeplan" icon={5}/></Col>
                {/*<Col><MenuItem title="Beratung" icon={3}/></Col>*/}
                <Col><MenuItem title="Kontakt" icon={4}/></Col>
            </Row>
        </Container>
    )
}

export default FooterMenu;