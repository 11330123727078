import React from 'react';
import {useState} from 'react';

import MenuItem from './MenuItem';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';



const Formular = (props) => {
    

    const [surName, setSurName] = useState('');
    const [lastName, setLastName] = useState('');
    const [mail, setMail] = useState('');
    const [number, setNumber] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);

    // 👇️ called every time input's value changes
    const handleSurName = event => {
        setSurName(event.target.value);
    };
    const handleLastName = event => {
        setLastName(event.target.value);
    };
    const handleMail = event => {
        setMail(event.target.value);
    };
    const handleNumber = event => {
        setNumber(event.target.value);
    };
    
    return (
        <div >               
            <Container className="lieferartContainer">
                <form method="post" action="form-to-mail.php"> 
                
                    <Row className="lieferartRow">                 
                        <Col xs="6" >
                            <label class="labl" className="lieferbox"> 
                                <input className="label-hidden" type="radio" name="lieferart" value="Abholung" checked="checked"/>
                                <img src="img/abholen.svg" />
                                <div className="label-title">Abholung</div>
                            </label> 
                        </Col>
                        <Col xs="6" >
                            <label class="labl" className="lieferbox">
                                <input className="label-hidden" type="radio" name="lieferart" value="Versand" />
                                <img src="img/liefern.svg" />
                                <div className="label-title">Versand</div>
                            </label>  
                        </Col>  
                    </Row>
                    <Row className="form-row"> 
                    <label class="labl" className="fileupload"> 
                            <div > <img class="upload-image" src="img/upload.svg" /><input className=""
          type="file"
          value={selectedFile}
          onChange={(e) => setSelectedFile(e.target.files[0])}
        /></div>
        
        </label> 
                        
                    </Row>
                    <Row className="form-row"> 
                        <div class="formular-flex">
                    <div class="formular-flex-item">
		<p><label> Ihr Vorname<br />
        <input
        id="surName"
        name="surName"
        type="text"
        onChange={handleSurName}
        value={surName}
      /> </label>
		</p>
	</div>
    <div class="formular-flex-item">
		<p><label> Ihr Nachname<br />
        <input
        id="lastName"
        name="lastName"
        type="text"
        onChange={handleLastName}
        value={lastName}
      />
 </label>
		</p>
	</div>
    </div>
    <div class="formular-flex">
                    <div class="formular-flex-item">
		<p><label> Ihre E-Mail-Adresse<br />
        <input
        id="mail"
        name="mail"
        type="text"
        onChange={handleMail}
        value={mail}
      /></label>
		</p>
	</div>
    <div class="formular-flex-item">
		<p><label> Ihre Telefonnummer<br />
        <input
        id="number"
        name="number"
        type="text"
        onChange={handleNumber}
        value={number}
      /></label>
		</p>
	</div>
    </div>
    <div class="submitbutton">
        <label>
    <input type="submit" value="Submit"></input>
    </label>
    </div>
                    </Row>


                            </form>
                       
                        
                    
                
            </Container>
           
        </div>        
    )
}

export default Formular;